import { appState, constants, utilities } from '@aim/common';
import { pathToActiveWhen, registerApplication, start } from 'single-spa';

const activeWhenEvents = pathToActiveWhen('/events');
const activeWhenEventWebinar = pathToActiveWhen('/events/:eventId/webinar');
const activeWhenEventBreakoutRoom = pathToActiveWhen(
  '/events/:eventId/breakoutroom'
);
const appName = 'aim-website-root-config';

registerApplication({
  name: '@aim/website',
  app: () => System.import('@aim/website'),
  activeWhen: (location) => {
    const url = new URL(location.href);
    return (
      activeWhenEvents(url) &&
      !activeWhenEventWebinar(url) &&
      !activeWhenEventBreakoutRoom(url)
    );
  },
  customProps: {
    domElement: document.getElementById('content-container'),
  },
});

registerApplication({
  name: '@aim/webinar',
  app: () => System.import('@aim/webinar'),
  activeWhen: (location) => {
    const url = new URL(location.href);
    return activeWhenEventWebinar(url);
  },
  customProps: {
    domElement: document.getElementById('content-container'),
  },
});

registerApplication({
  name: '@aim/breakoutroom',
  app: () => System.import('@aim/breakoutroom'),
  activeWhen: (location) => {
    const url = new URL(location.href);
    return activeWhenEventBreakoutRoom(url);
  },
  customProps: {
    domElement: document.getElementById('content-container'),
  },
});

registerApplication({
  name: '@aim/loader',
  app: () => System.import('@aim/loader'),
  activeWhen: () => true,
  customProps: {
    domElement: document.getElementById('loader-container'),
    appName,
  },
});

appState.template.next(constants.Templates.FRONTEND);

window.addEventListener('load', () => {
  const mainContainer = window.document.getElementById('main-container');
  appState.mainContainerSize.next({
    height: mainContainer.clientHeight,
    width: mainContainer.clientWidth,
  });
});

window.addEventListener('error', async (props) => {
  if (
    // is a browser error that prevents page loops... this is not an error
    props.message !==
    'ResizeObserver loop completed with undelivered notifications.'
  ) {
    await utilities.printSourceMappedStackTrace(
      props?.error?.stack,
      props?.error || { message: props.message, type: props.type }
    );
  }
});

start({
  urlRerouteOnly: true,
});
